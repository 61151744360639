import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import deviceSaga from "./deviceSaga";
import userSaga from "./userSaga";
import peripheralSaga from "./peripheralSaga";

export default function* rootSaga() {
  console.log("Root Saga is running");
  yield all([userSaga(), authSaga(), peripheralSaga(), deviceSaga()]);
}
