import styles from "./modal.module.scss";

/* REDUX */
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MODAL } from "../../redux/actions";

import IconTimes from "../../assets/icons/fluent_add-48-regular.svg";
import IconBack from "../../assets/icons/fluent_arrow-left-48-regular.svg";

const Modal = ({ children, previousModal, noClose }) => {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const handleBack = () => {
    if (modal.previousModal) {
      dispatch(SHOW_MODAL(modal.previousModal));
    }
  };

  const handleClose = () => {
    dispatch({ type: "HIDE_MODAL" });
  };

  const convertSize = (size) => {
    switch (size) {
      case "m":
      case "medium":
        return styles.modal__box__m;
      case "l":
      case "large":
        return styles.modal__box__l;
      default:
        return styles.modal__box;
    }
  };

  return modal.active ? (
    <div className={styles.modal}>
      <div className={convertSize(modal.size)}>
        <div className={styles.modal__box__header}>
          <h2 className={styles.modal__box__header__title}>{modal.title}</h2>
          {modal.previousModal && (
            <button
              className={styles.modal__box__header__back}
              onClick={handleBack}
            >
              <img src={IconBack} alt="back" />
            </button>
          )}
          {!modal.previousModal ||
            (!modal.noClose && (
              <button
                className={styles.modal__box__header__close}
                onClick={handleClose}
              >
                <img src={IconTimes} alt="close" />
              </button>
            ))}
        </div>
        <div className={styles.modal__box__content}>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Modal;
