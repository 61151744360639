const initState = {
  loaded: true,
  data: {},
  customer: sessionStorage.getItem("screencom-customer")
    ? JSON.parse(sessionStorage.getItem("screencom-customer"))
    : [],
  roles: [],
  errors: [],
  socket: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_PERMISSIONS_REQUEST":
      return { ...state, loaded: false };
    case "FETCH_PERMISSIONS_SUCCESS":
      return {
        ...state,
        loaded: true,
        data: action.payload,

        errors: [],
      };
    case "FETCH_PERMISSIONS_FAILURE":
      return { ...state, loaded: true, data: {}, errors: action.payload };

    default:
      return state;
  }
};

export default userReducer;
