import axios from "axios";
import { call, takeLatest } from "redux-saga/effects";
import { SEND_DEVICECMD_FAILURE, SEND_DEVICECMD_SUCCESS } from "../actions";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

export function* onSendDeviceCMD(action) {
  try {
    const accessToken = yield call(authSaga, {
      type: "SEND_DEVICECMD_REQUEST",
      payload: action.payload,
    });

    if (accessToken) {
      const res = yield call(
        axios.post,
        `${URL}/device/sendCmd`,
        action.payload,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      console.log("Antwort von Backend:", res.data);

      let commandStatus = false;
      if (res.data?.response?.status === true) {
        commandStatus = true;
      } else if (res.data.status === "false") {
        commandStatus = false;
      }

      const message =
        res.data?.response?.message ||
        res.data?.message ||
        "Unbekannter Fehler";

      if (action.callback) {
        console.log("Callback wird ausgeführt mit:", commandStatus, message);
        action.callback(commandStatus, message);
      } else {
        console.error("Callback ist nicht definiert");
      }
    }
  } catch (err) {
    console.log("Fehler in der Saga:", err);
    if (action.callback) {
      action.callback(false, "Fehler beim Senden des Befehls");
    }
  }
}

export default function* deviceSaga() {
  yield takeLatest("SEND_DEVICECMD_REQUEST", onSendDeviceCMD);
}
