import { combineReducers } from "redux";
import userReducer from "./userReducer";
import peripheralReducer from "./peripheralReducer";
import modalReducer from "./modalReducer";

const rootReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  peripherals: peripheralReducer,
});

export default rootReducer;
