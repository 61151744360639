import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import "../../index.css";
import "./app.scss";
import Door from "../door/door";
import Spinner from "../../components/spinner/Spinner";
import Button from "../../components/button/button";
import Modal from "../../components/modal/modal";
import Logo from "../../assets/sc-eportier-logo.svg";
import { FETCH_PERMISSIONS_REQUEST, SHOW_MODAL } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const user = useSelector((state) => state.user);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialisieren der Microsoft Teams App
    microsoftTeams.app
      .initialize()
      .then(() => {
        console.log("Microsoft Teams app initialized successfully.");
        setIsInitialized(true);
        dispatch(FETCH_PERMISSIONS_REQUEST());
      })
      .catch((error) => {
        console.error("Error initializing Microsoft Teams app:", error);
      });
  }, [dispatch]);

  const hasRequiredPermissions = () => {
    if (user.data && Array.isArray(user.data.permissions)) {
      const roles = user.data.permissions;
      console.log(user.data);
      return roles.includes("Admin") || roles.includes("Visitor.Door");
    }
    return false;
  };

  useEffect(() => {
    if (isInitialized && user.data && Array.isArray(user.data.permissions)) {
      const roles = user.data.permissions;

      if (
        !roles.includes("Admin") &&
        !roles.includes("Visitor.Admin") &&
        !roles.includes("Visitor.Manager") &&
        !roles.includes("Visitor.User") &&
        !roles.includes("Visitor.Door")
      ) {
        dispatch(
          SHOW_MODAL({
            type: "unauthorized-role",
            title: "Berechtigung erforderlich",
            noClose: "true",
          })
        );
      }
    }
  }, [user.data]);

  useEffect(() => {
    if (isInitialized && user.errors?.response?.status === 401) {
      dispatch(
        SHOW_MODAL({
          type: "unauthorized",
          title: "Konto ist nicht freigeschalten",
        })
      );
    }
  }, [user.errors]);

  return (
    <div>
      <header className="bg-white py-4 w-full">
        <div className="flex justify-center md:justify-between items-center w-full px-4 md:px-8">
          <div className="flex items-center">
            <img src={Logo} alt="Logo" className="h-20" />
          </div>
        </div>
      </header>

      {!isInitialized ? (
        <Spinner />
      ) : hasRequiredPermissions() ? (
        <Door />
      ) : null}

      <Modal title={modal.title}>
        {modal.type === "unauthorized" && (
          <div className="unauthorized">
            <p>
              Ihr Konto wurde für die Anwendung nicht freigeschalten. Bitte
              wenden Sie sich an den Support.
            </p>
            <br />
            <div className="unauthorized__actions">
              <Button
                type="primary"
                text="Support kontaktieren"
                onClick={() => {
                  window.location.href =
                    "mailto:servicedesk@vocom.ch?subject=Zugriff%20ePortier%20Teams%20App";
                }}
              />
            </div>
          </div>
        )}

        {modal.type === "unauthorized-role" && (
          <div className="unauthorized">
            <p>
              Sie verfügen nicht über die Berechtigungen. Bitte wenden Sie sich
              an den Administrator.
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
}
