export const FETCH_PERMISSIONS_REQUEST = (payload) => {
  return { type: "FETCH_PERMISSIONS_REQUEST", payload };
};

export const FETCH_PERMISSIONS_SUCCESS = (payload) => {
  return { type: "FETCH_PERMISSIONS_SUCCESS", payload };
};

export const FETCH_PERMISSIONS_FAILURE = (payload) => {
  return { type: "FETCH_PERMISSIONS_FAILURE", payload };
};

export const SEND_DEVICECMD_REQUEST = (payload, callback) => ({
  type: "SEND_DEVICECMD_REQUEST",
  payload,
  callback,
});

export const SEND_DEVICECMD_SUCCESS = (payload) => {
  return { type: "SEND_DEVICECMD_SUCCESS", payload };
};

export const SEND_DEVICECMD_FAILURE = (payload) => {
  return { type: "SEND_DEVICECMD_FAILURE", payload };
};

export const FETCH_PERIPHERALS_REQUEST = (payload) => {
  return { type: "FETCH_PERIPHERALS_REQUEST", payload };
};

export const FETCH_PERIPHERALS_SUCCESS = (payload) => {
  return { type: "FETCH_PERIPHERALS_SUCCESS", payload };
};

export const FETCH_PERIPHERALS_FAILURE = (payload) => {
  return { type: "FETCH_PERIPHERALS_FAILURE", payload };
};

export const SHOW_MODAL = (payload) => {
  return { type: "SHOW_MODAL", payload };
};

export const HIDE_MODAL = () => {
  return { type: "HIDE_MODAL" };
};
