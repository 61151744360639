import axios from "axios";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_PERIPHERALS_FAILURE,
  FETCH_PERIPHERALS_SUCCESS,
} from "../actions";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

export function* onFetchPeripheral(action) {
  try {
    const accessToken = yield call(authSaga, {
      type: "FETCH_PERIPHERALS_REQUEST",
      payload: action.payload,
    });
    if (accessToken) {
      const res = yield axios.post(URL + "/device/peripheral", action.payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      yield put(FETCH_PERIPHERALS_SUCCESS(res.data.peripheral));
    }
  } catch (err) {
    console.log(err);
    toast.error(err.message);
    yield put(FETCH_PERIPHERALS_FAILURE(err));
  }
}

export default function* peripheralSaga() {
  yield takeLatest("FETCH_PERIPHERALS_REQUEST", onFetchPeripheral);
}
