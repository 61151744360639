import React from 'react';
import styles from './button.module.scss';

const Button = ({ type, text, icon: Icon, ...props }) => {
  const setType = (type) => {
    switch (type) {
      case 'primary':
        return styles.button__primary;
      case 'outline':
        return styles.button__outline;
      default:
        return styles.button;
    }
  };

  return (
    <button type='submit' className={setType(type)} {...props}>
      {Icon && <Icon className={styles.icon} />}
      {text}
    </button>
  );
};

export default Button;
