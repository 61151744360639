import axios from "axios";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_PERMISSIONS_FAILURE,
  FETCH_PERMISSIONS_SUCCESS,
} from "../actions";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

console.log("user saga loaded!");

export function* onFetchPermissions(action) {
  console.log("Permission started");
  try {
    console.log("permission try");
    const accessToken = yield call(authSaga, {
      type: "FETCH_PERMISSIONS_REQUEST",
    });
    if (accessToken) {
      const res = yield axios.get(URL + "/user/permissions", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (res.data.status === false) {
        yield put(
          FETCH_PERMISSIONS_FAILURE({
            status: res.data.status,
            message: res.data.message,
          })
        );
      } else {
        yield put(
          FETCH_PERMISSIONS_SUCCESS({
            ...res.data.user,
            customer_id: res.data.customer_id,
            user_id: res.data.user_id,
            permissions: res.data.permissions.map(
              (permission) => permission.role_name
            ),
          })
        );
      }
    }
  } catch (err) {
    toast.error(err.message);
    yield put(FETCH_PERMISSIONS_FAILURE(err));
  }
}

export default function* userSaga() {
  yield takeLatest("FETCH_PERMISSIONS_REQUEST", onFetchPermissions);
}
