import { call } from "redux-saga/effects";
import * as microsoftTeams from "@microsoft/teams-js";

function* authSaga(action) {
  try {
    console.log("load auth saga");
    yield call(ensureTeamsInitialized);

    const accessToken = yield call(getTeamsToken);

    if (accessToken) {
      return accessToken;
    } else {
      throw new Error("Kein Zugriffstoken erhalten");
    }
  } catch (error) {
    console.error("Fehler bei der Authentifizierung", error);

    if (error.message === "Kein Zugriffstoken erhalten") {
      yield call(
        [
          microsoftTeams.authentication,
          microsoftTeams.authentication.authenticate,
        ],
        {
          successCallback: (result) => {
            return result;
          },
          failureCallback: (err) => {
            throw new Error(err);
          },
        }
      );
    } else {
      throw error;
    }
  }
}

function ensureTeamsInitialized() {
  return new Promise((resolve, reject) => {
    microsoftTeams.app
      .initialize()
      .then(() => {
        console.log(
          "Microsoft Teams app initialized successfully in authSaga."
        );
        resolve();
      })
      .catch((error) => {
        console.error("Error initializing Microsoft Teams app:", error);
        reject(error);
      });
  });
}

async function getTeamsToken() {
  try {
    const token = await microsoftTeams.authentication.getAuthToken();
    return token;
  } catch (error) {
    console.error("Error getting token from Teams: ", error);
    throw error;
  }
}

export default authSaga;
