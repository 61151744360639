const initState = {
  loaded: false,
  data: [],
  errors: [],
};

const peripheralReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_PERIPHERALS_REQUEST":
      return { ...state, loaded: false };
    case "FETCH_PERIPHERALS_SUCCESS":
      return { ...state, loaded: true, data: action.payload };
    case "FETCH_PERIPHERALS_FAILURE":
      return { ...state, loaded: true, errors: action.payload };
    default:
      return state;
  }
};

export default peripheralReducer;
