import "./door.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_PERIPHERALS_REQUEST,
  SEND_DEVICECMD_REQUEST,
} from "../../redux/actions";
import Preloader from "../../components/preloader/preloader";
import { Door28Regular } from "@fluentui/react-icons";

const Door = () => {
  const peripherals = useSelector((state) => state.peripherals);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState(null);
  const [commandStatus, setCommandStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");

  const handleCardClick = (cardIndex, peripheral) => {
    setActiveCard(cardIndex);
    setCommandStatus("inProgress");
    setStatusMessage("Die Türe wird geöffnet...");

    dispatch(
      SEND_DEVICECMD_REQUEST(
        {
          device_id: peripheral.device_id,
          command: "ip_relay-open",
          parameters: {
            ip: peripheral.additional_info.ip,
          },
        },
        (status, message) => {
          console.log("Status zurückgegeben:", status);
          console.log("Nachricht zurückgegeben:", message);

          if (status) {
            setCommandStatus("success");
            setStatusMessage("Die Türe wurde erfolgreich geöffnet");
          } else {
            setCommandStatus("error");
            setStatusMessage("Die Türe konnte nicht geöffnet werden");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (user.customer && user.data) {
      dispatch(
        FETCH_PERIPHERALS_REQUEST({
          customer_id:
            Array.isArray(user.data.permissions) &&
            user.data.permissions.includes("Admin")
              ? user.customer.customer_id
              : user.data.customer_id,
          type: "ip-relay",
          include_screen: true,
          include_device: false,
          include_location: true,
        })
      );
    }
  }, [user.customer, dispatch]);

  useEffect(() => {
    if (activeCard !== null) {
      const timer = setTimeout(() => {
        setActiveCard(null);
        setCommandStatus(null);
        setStatusMessage("");
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [activeCard]);

  return (
    <div className="home">
      {!peripherals.loaded ? (
        <div className="w-full py-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="relative bg-white p-6 border border-[#e0e0e0] flex flex-col md:flex-row items-center md:items-center rounded-md">
              <div className="bg-screencom text-black flex items-center justify-center w-[80px] h-[80px] rounded-full mb-4 md:mb-0 md:mr-6 shrink-0">
                <Door28Regular className="w-12 h-12" />
              </div>
              <div className="flex-grow flex flex-col items-center md:items-start justify-center"></div>
            </div>
          </div>
          <Preloader />
        </div>
      ) : peripherals.data && peripherals.data.length > 0 ? (
        (console.log(peripherals.data),
        peripherals.data.map((peripheral, index) => (
          <div className="w-full py-6" key={peripheral.id}>
            <h1 className="font-inter_bold text-3xl pb-4">
              {peripheral.location.location_name}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div
                className={`relative bg-white p-6 border border-[#e0e0e0] flex flex-col md:flex-row items-center md:items-center rounded-md cursor-pointer ${
                  activeCard === index ? "pb-20" : ""
                }`}
                onClick={() => handleCardClick(index, peripheral)}
              >
                {activeCard === index && (
                  <>
                    <div className="absolute top-0 left-0 w-full">
                      {commandStatus === "inProgress" && (
                        <div className="relative w-full h-1 bg-gray-200">
                          <div className="absolute top-0 left-0 h-1 bg-screencom animate-progress-bar rounded-tl-md rounded-tr-md"></div>
                        </div>
                      )}
                    </div>
                    <div className="absolute bottom-0 left-0 w-full">
                      <p
                        className={`text-black font-semibold text-center w-full py-1 rounded-bl-md rounded-br-md ${
                          commandStatus === "error"
                            ? "bg-[#e55039]"
                            : "bg-screencom"
                        }`}
                      >
                        {statusMessage}
                      </p>
                    </div>
                  </>
                )}
                <div className="bg-screencom text-black flex items-center justify-center w-[80px] h-[80px] rounded-full mb-4 md:mb-0 md:mr-6 shrink-0">
                  <Door28Regular className="w-12 h-12" />
                </div>
                <div className="flex-grow flex flex-col items-center md:items-start justify-center">
                  <p className="font-inter_semibold text-2xl pb-2">
                    {peripheral.name}
                  </p>
                  <p className="text-gray-600 font-inter_regular text-2xl">
                    {peripheral.screen.location_description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )))
      ) : (
        <p>Keine Peripherie verfügbar</p>
      )}
    </div>
  );
};

export default Door;
