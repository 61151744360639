const initState = {
  active: false,
  title: '',
  type: '',
  size: '',
  close: false,
};

const modalReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        active: true,
        title: action.payload.title,
        type: action.payload.type,
        size: action.payload.size,
        close: action.payload.close,
        previousModal: action.payload.previousModal,
      };
    case 'HIDE_MODAL':
      return { ...state, active: false };
    default:
      return state;
  }
};

export default modalReducer;
